import moment from "moment";
export function useCustomComposable() {
    function debounce(func, timeout = 300) {
        let timer = null;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(null, args); }, timeout);
        };
    }
    function setTitle({title = "", prefix = ""}) {
        document.title = prefix + title;
    }
    function makeUniqueId(length = 6) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
        }
        return result;
    }
    return {
        setTitle,
        makeUniqueId,
        debounce
    }
}
export function useConvertDate() {
    function convertDateFormat(date) {
        try {
            if(!date) {
                return;
            }
            if(date.seconds) {
                return moment(new Date(date.seconds * 1000)).format("DD MMM, YYYY");
            } else {
                return moment(new Date(date)).format("DD MMM, YYYY");
            }
        } catch (error) {
            console.error('ERROR in due date convert function', error);
        }
    }

    return {
        convertDateFormat
    }
}