import { createRouter, createWebHashHistory } from 'vue-router'
import { useCustomComposable } from '@/composable'
const routes = [
    {
        component: () => import('../views/UserMainView/MainView.vue'),
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                meta: {
                    title: 'Dashboard',
                    requiresAuth: true
                },
                // route level code-splitting
                // this generates a separate chunk (dashboard.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Dashboard" */ '../components/TabComponents/DashboardTab.vue'),
            },
            {
                path: '/addon',
                name: 'AddOn',
                meta: {
                    title: 'Add On',
                    requiresAuth: true
                },
                // route level code-splitting
                // this generates a separate chunk (dashboard.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Addon" */ '../components/TabComponents/AddOn.vue'),
            },
            {
                path: '/support',
                name: 'Support',
                meta: {
                    title: 'Support',
                    requiresAuth: true
                },
                // route level code-splitting
                // this generates a separate chunk (support.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "Support" */ '../components/TabComponents/SupportTab.vue'),
            },
            {
                path: '/dashboard/:id',
                name: 'DashboardDetail',
                meta: {
                    title: 'Dashboard Detail',
                    requiresAuth: true
                },
                // route level code-splitting
                // this generates a separate chunk (dashboard.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "DashboardDetail" */ '../components/TabComponents/DashboardDetails.vue'),
            },
        ]
    },
    {
        path: '/signin',
        name: 'Signin',
        // route level code-splitting
        // this generates a separate chunk (signin.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Signin" */ '../views/Authentication/SigninForm/SigninForm.vue'),
        meta: {
            title: "Signin",
            requiresAuth: false,
        },
    },
    {
        path: '/callback',
        name: 'CallBack',
        component: () => import(/* webpackChunkName: "NotFound" */'../views/Authentication/Envato/callBack.vue'),
        meta: {
            title: 'CallBack',
            requiresAuth: false
        }
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import(/* webpackChunkName: "NotFound" */'../views/Authentication/Envato/envatoError.vue'),
        meta: {
            title: 'Error',
            requiresAuth: false
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */'../views/NotFound.vue'),
        meta: {
			title: '404',
			requiresAuth: true
		}
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});
const {setTitle} = useCustomComposable();
router.beforeEach((to, _, next) => {
    let isUserLog = localStorage.getItem('logged');
    const requiresAuth = to.meta.requiresAuth;

    if(isUserLog === 'true'){
        if(to.path === '/'){
            next({name: "Dashboard"});
            return;
        }
    }
    setTitle({title: to.meta.title, prefix: "Alian Hub | "});

    if((isUserLog == 'false' || isUserLog == null) && (requiresAuth === true || requiresAuth === undefined)) {
        next({name: 'Signin', query: {redirect_url: window.location.pathname}});
        return;
    } else if(isUserLog == 'true' && requiresAuth === false) {
        next({name: "Dashboard"});
        return;
    } else {
        next();
        return;
    }
})

export default router