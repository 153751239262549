// API's
module.exports.LICENSES = "/api/v1/licenses";
module.exports.UPDATELICENSES = "/api/v1/updateLicenses";
module.exports.GENERATETOKEN = '/api/v1/generateToken';
module.exports.API_URI = process.env.VUE_APP_APIURL;
module.exports.MONGO_OPRATION = '/api/v1/mongoOpration';
module.exports.CREATE_USER = '/api/v1/createUser';
module.exports.VERIFY_EMAIL = '/api/v1/verifyEmail';
module.exports.SEND_VARIFICATION_EMAIL = '/api/v1/sendVerificationEmail';
module.exports.MONGO_OPRATION = '/api/v1/mongoOpration';
module.exports.SETACCESSTOKEN = '/api/v1/setAccessToken';
module.exports.DOWNLOAD_SAAS = process.env.VUE_APP_DOWNLOAD_SAAS;
module.exports.LICENSES_DOMAIN = "/api/v1/addLicenseDomain";
module.exports.GET_LICENSES_DOMAIN = "/api/v1/licensesDomain";
module.exports.DELETE_LICENSES_DOMAIN = "/api/v1/deletelicensesDomain";
module.exports.CREATE_TICKET = "/api/v1/createSupportTicket";
module.exports.GET_TICKET = "/api/v1/getSupportTicket";
module.exports.DELETE_TICKET = "/api/v1/deleteTicket";
module.exports.EDIT_TICKET = "/api/v1/editTicket";
