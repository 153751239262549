import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import "@/assets/css/style.css";
const app = createApp(App).use(store).use(router);
app.mount('#app')
app.use(ToastPlugin,{position: 'top-right'});
// MODAL
let element = document.createElement('div');
element.id="my-modal"
document.getElementById("app")?.appendChild(element)
// DROP DOWN
element = document.createElement('div');
element.id="my-dropdown"
document.getElementById("app")?.appendChild(element)
