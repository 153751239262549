<template>
    <div style="height:100vh;">
        <router-view/>
    </div>
</template>

<script setup>
    import { provide, ref,onMounted } from 'vue'
    import { useRouter } from 'vue-router';
    const router = useRouter();
    import axios from 'axios';
    import * as env from '@/config/env';
    const userId = ref('')
    const clientWidth = ref(document.documentElement.clientWidth);

    onMounted(() => {
        window.onresize = (e) => {
            clientWidth.value = e.target.innerWidth;
        }
        let isUserLog = localStorage.getItem('logged');
        if (isUserLog !== null && isUserLog != 'false') {
            userId.value = localStorage.getItem('userId');
        }
        setInterval(() => {
            sessionTimeout();
        }, 10000);
    })
    provide("$clientWidth", clientWidth);
    provide("$userId", userId);
    function sessionTimeout() {
        const value = sessionStorage.getItem('sessionTimeout') || 0;
        const currentTime = new Date().getTime();
        if (value < currentTime) {
            if(router.currentRoute.value.meta.requiresAuth) {
                axios.post(env.API_URI + env.SETACCESSTOKEN, {userId: userId.value}).then(async ()=>{
                    sessionStorage.setItem('sessionTimeout', new Date().getTime()+ (1* 60 * 60 * 1000));
                }).catch((error)=>{
                    console.error(error);
                    localStorage.removeItem("userId");
                    localStorage.setItem("logged",false);
                    localStorage.removeItem("token");
                    sessionStorage.removeItem("sessionTimeout");
                    sessionStorage.removeItem("userData");
                    router.push({name:"Signin"});
                    window.location.reload();
                })
            }
        }
    }
</script>


